import Swiper from "swiper";
import { Pagination, EffectFade } from "swiper/modules";
import L from 'leaflet';

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const locations = document.querySelectorAll('.location__map');

  function handleDOMContentLoaded() {
    new Swiper('#slideshow', {
      modules: [Pagination, EffectFade],
      effect: 'fade',
      speed: 400,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        bulletElement: 'span',
        clickable: true
      },
      fadeEffect: {
        crossFade: true
      }
    });

    new Swiper('#step-items', {
      slidesPerView: 'auto',
      freeMode: true,
      breakpoints: {
        768: {
          slidesPerView: 3
        },
        1024: {
          slidesPerView: 'auto'
        }
      }
    });

    new Swiper('#news-slider', {
      slidesPerView: 'auto',
      spaceBetween: 21,
      on: {
        reachBeginning: (swiper) => {
          swiper.el.classList.remove('swiper--end', 'swiper--move');
          swiper.el.classList.add('swiper--begin');
        },
        reachEnd: (swiper) => {
          swiper.el.classList.remove('swiper--begin', 'swiper--move');
          swiper.el.classList.add('swiper--end');
        },
        init: (swiper) => {
          swiper.el.classList.add('swiper--begin');
        }
      }
    });

    const icon = L.icon({
      iconUrl: '/theme/images/pin.svg',
      iconSize: [73, 73],
      iconAnchor: [36, 36],
      popupAnchor: [0, -35]
    });

    locations.forEach((el) => {
      const lat = el.dataset.latitude;
      const lng = el.dataset.longitude;
      const balloonTitle = el.dataset.balloon;
      const mapUrl = decodeURI(el.dataset.url);

      const map = L.map(el, {
        scrollWheelZoom: false,
        dragging: true,
        tap: true,
        zoomControl: false
      }).setView([lat, lng], 15);

      L.control.zoom({
        position: 'bottomright'
      }).addTo(map);

      L.tileLayer('https://api.mapbox.com/styles/v1/ifiorisemplici/clmriyhr6028f01r468vu6gq4/tiles/256/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: '<a href="https://www.mapbox.com/">Mapbox</a>',
        minZoom: 4,
        maxZoom: 18,
        id: 'mapbox.streets',
        accessToken: 'pk.eyJ1IjoiaWZpb3Jpc2VtcGxpY2kiLCJhIjoiY2xtcTY1OGh0MDBmaTJrdGwxeTYxcjN5dSJ9.trW9QnhdqgGy5xjG_7LQ3A'
      }).addTo(map);

      const marker = L.marker([lat, lng], { icon: icon });
      let content = `<div class="location__balloon"><p>${balloonTitle}</p><p><a href="${mapUrl}" target="_blank">Apri su Google Maps</a></p></div>`;
      marker.bindPopup(content);
      map.addLayer(marker);
      marker.openPopup();
    });
  }
})();
